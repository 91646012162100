<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:login_at="{ item }">
            {{ Common.TimeFormat(item.login_at) }}
          </template>
          <template v-slot:id="{ item }">
            <a @click="DeleteAction(item.id)" data-bs-toggle="tooltip" :title="$t('LOGINRECORD.DELETE-ITEM')">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBContainer, MDBIcon, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import Swal from "sweetalert2";
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "LoginRecord",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
    MDBCard,
    MDBCardBody
  },
  setup() {
    const i18n = useI18n();
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const desserts = ref([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 50,
      sortBy: ["login_at"],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width: 50
      },
      { text: i18n.t("COMMON.NAME"), value: "authenticatable.name" },
      { text: i18n.t("COMMON.ACCOUNT"), value: "authenticatable.email" },
      { text: i18n.t("COMMON.LOGINIP"), value: "ip_address" },
      { text: i18n.t("COMMON.LOGINTIME"), value: "login_at" },
      { text: i18n.t("COMMON.DEVICE"), value: "user_agent", sortable: false }
    ]);

    const GetLoginRecord = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/LoginRecord",
          {
            params: CommonService.SortHandleObject(options)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      Swal.fire({
        title: i18n.t("COMMON.WARNING"),
        html: i18n.t("COMMON.CONFIRM-DELETE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("COMMON.CONFIRM"),
        cancelButtonText: i18n.t("COMMON.CANCEL")
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/LoginRecord/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetLoginRecord();
              }
            }
          );
        }
      });
    };

		return {
      loading,
      options,
      headers,
      total,
      desserts,
      Common,
      GetLoginRecord,
      DeleteAction
		};
  },
  mounted() {
    this.GetLoginRecord();
  },
  watch: {
		options: {
      handler() {
        this.GetLoginRecord();
      },
      deep: true
    }
  }
};
</script>